import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

type HeatMapData = {
  [key: string]: {
    "Outperforming Nifty": number;
    "Return Difference": number;
    "Display String": string;
  };
};

const HeatMap = () => {
  const [heatmapData, setHeatmapData] = useState<{ name: string; data: any }[]>([]);
  const [tooltipData, setTooltipData] = useState<{ [key: string]: string[] }>({});
  const [data, setData] = useState<HeatMapData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [colorRanges, setColorRanges] = useState<any[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") + `/Stock-Selection/analysis-fabric-files/Momentum_Value_Strategy/Momentum_Value_Strategy-grid.json`
      );
      const data = await response.json();
      processData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const processData = (data: HeatMapData) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const heatmap: { [key: string]: (number | null)[] } = {};
    const tooltips: { [key: string]: string[] } = {}; // Always strings, no null
    let minValue = Infinity;
    let maxValue = -Infinity;

    Object.keys(data).forEach(key => {
      const [month, year] = key.split(' ');
      const { 'Return Difference': returnDifference, 'Display String': displayString } = data[key];

      // Update min/max values
      minValue = Math.min(minValue, returnDifference);
      maxValue = Math.max(maxValue, returnDifference);

      if (!heatmap[year]) heatmap[year] = Array(12).fill(null);
      const monthIndex = months.indexOf(month);
      heatmap[year][monthIndex] = returnDifference;

      if (!tooltips[year]) tooltips[year] = Array(12).fill('');
      tooltips[year][monthIndex] = displayString || ''; // Ensure it is a non-null string
    });

    setHeatmapData(Object.keys(heatmap).map(year => ({
      name: year,
      data: heatmap[year].map((value, index) => ({
        x: months[index],
        y: value,
      })),
    })));
    
    setTooltipData(tooltips);
    setColorRanges(generateColorRanges(minValue, maxValue));
    setIsLoading(false);
  };

  const generateColorRanges = (minValue: number, maxValue: number) => {
    return [
      {
        from: minValue,
        to: 0,
        name: 'UnderPerformed',
        color: '#AF150B',
      },
      {
        from: 0,
        to: maxValue,
        name: 'OutPerformed',
        color: '#278E03',
      },
      {
        from: null,
        to: null,
        name: 'No Data',
        color: '#FFFFFF', // White for missing data
      },
    ];
  };

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'heatmap',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 1,
        radius: 0,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: colorRanges, // Dynamically set the color scale ranges
        },
      },
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], // Months
    },
    yaxis: [
      {
        labels: {
          formatter: (value, index) => {
            const year = Object.keys(tooltipData)[index];
            // Ensure the returned value is always a string
            return year || String(value);
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: (value, { seriesIndex, dataPointIndex }) => {
          const year = Object.keys(tooltipData)[seriesIndex];
          if (tooltipData[year]) {
            const displayString = tooltipData[year][dataPointIndex];
            if (displayString !== '') {
              return displayString;
            }
          }
          return value !== null && value !== undefined ? value.toString() : 'N/A';
        },
      },
    },
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ApexCharts options={options} series={heatmapData} type="heatmap" height={350} />
    </div>
  );
};

export default HeatMap;
