import React, { useContext, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Header from "../Header/Header";
import Landing from "../Component/Landing/Landing";
import Menu from "../Component/Menu/Menu";
import Dashboard from "../Component/Dashboard/Dashboard";
// import Landing from "../Component/Landing/Landing";
// import Login from "../Component/Login/Login";
import Search from "../Component/Search/Search";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import ChatBot from "../Component/ChatBot/Chatbot";
import NewsAggregator from "../Component/NewsAggregator/NewsAggregator";
import LandingV2 from "../Pages/LandingV2";
import TrendingPage from "../Pages/TrendingPage";
import StockSelectionPage from "../Pages/StockSelectionPage";
import LoginPage from "../Pages/LoginPage";
import RegisterPage from "../Pages/RegisterPage";
import ProtectedPageHOC from "../Component/Auth/ProtectedHOC";

import { AuthContext } from "../context/AuthContext";
import Home, { strongStockSubNavData } from "../Component/StockSelection/Home";
import NiftyStatergy from "../Views/NiftyStatergy/NiftyStatergy";
import AggregatorV2 from "../Views/AggregatorV2/AggregatorV2";
import StockC2c from "../Views/StockC2C/StockC2C";
import Navbar from "../Component/LandingV2/Navbar";
import SubNavbar from "../Component/LandingV2/SubNavbar";
import NewStatergy from "../Views/NewStatergy/NewStatergy";
import ValueStatergy from "../Views/ValueStatergy/ValueStatergy";
import ForgotPasswordPage from "../Pages/ForgotPasswordPage";
import ResetPasswordPage from "../Pages/ResetPasswordPage";

const TRACKING_ID = "249691188"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function RequireAuth(route: any) {
  return route.children;
  const user = useSelector((state) => (state as any).user_authentication);
  return user.isLoggedin ? route.children : <Navigate to="/login" replace />;
}

const arry = [
  // "/dashboard",
  // "/search",
  "/detail",
  // "/aggregator",
];

const Router = () => {
  const location = useLocation();

  const { userInfo, setUserInfo } = useContext(AuthContext);

  return (
    <>
      {arry.includes(location.pathname) ? <Header /> : null}

      <div
        style={{
          position: "fixed",
          bottom: "10px",
          right: "46px",
          zIndex: "1051",
        }}
      >
        <ChatBot />
      </div>

      <Routes>
        {/* User auth routes */}
        <Route
          path="/login"
          element={<LoginPage setUserInfo={setUserInfo} />}
        />
        <Route
          path="/register"
          element={<RegisterPage setUserInfo={setUserInfo} />}
        />

        <Route path="/News/:ticker" element={<Menu />} />

        <Route path="/News/:ticker/:newsid" element={<Menu />} />

        <Route path="/Tweets/:ticker" element={<Menu />} />

        <Route path="/Tweets/:ticker/:tweetid" element={<Menu />} />

        <Route
          path="/TechnicalAnalysis/:ticker"
          element={
            <RequireAuth>
              <Menu />
            </RequireAuth>
          }
        />

        {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}

        {/* <Route path="/" element={<Navigate to="/landing" replace />} /> */}

        {/* <Route path="/login" element={
      } /> */}

        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/aggregator" element={<NewsAggregator />} />

        <Route path="/aggregatorv2/:ticker" element={<AggregatorV2 />} />

        <Route path="/search" element={<Search />} />
        <Route path="/detail" element={<Menu />} />
        <Route path="/" element={<LandingV2 />} />

        {/* Protecting the trendings page */}
        <Route
          path="/trending"
          element={
            // <ProtectedPageHOC userInfo={userInfo} requiredScope={["view_trending"]}>
            <TrendingPage />
            // </ProtectedPageHOC>
          }
        />

        <Route
          path="/nifty-statergy"
          element={
            <>
              <Navbar />
              <SubNavbar navData={strongStockSubNavData} />
              <ProtectedPageHOC
                userInfo={userInfo}
                requiredScope={["page:investmentideas"]}
              >
                <NiftyStatergy />
              </ProtectedPageHOC>
            </>
          }
        />
        {/* Protecting the stockc2c page */}
        <Route path="/stock-c2c" element={<StockC2c />} />
        <Route path="/momentum-strategy" element={<NewStatergy />} />
        <Route path="/value-statergy" element={<ValueStatergy />} />
        {/* <Route
          path="/stock-selection"
          element={
            // <ProtectedPageHOC userInfo={userInfo}>
            <StockSelectionPage />
            // </ProtectedPageHOC>
          }
        /> */}
        {/* <Route
          path="/strong-stocks-home"
          element={
            <>
              <Navbar />
              <SubNavbar navData={strongStockSubNavData} />
              <ProtectedPageHOC
                userInfo={userInfo}
                requiredScope={["page:investmentideas"]}
              >
                <Home />
              </ProtectedPageHOC>
            </>
          }
        />
        /> */}
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default Router;
